import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { DataGridVariantRequestModel } from "./_models/DataGridVariantRequestModel";
import { DataGridVariantResponseModel } from "./_models/DataGridVariantResponseModel";

@Module
export default class TopbarManagementModule extends VuexModule {
  dataGridVariantResponseModel: Array<DataGridVariantResponseModel>;
  dafaultDataGridVariantResponseModel: DataGridVariantResponseModel;
  dataGridState: any;

  get getDataGridMyVariantList() {
    return this.dataGridVariantResponseModel;
  }

  get getMyDefaultVariant() {
    return this.dafaultDataGridVariantResponseModel;
  }

  get getDataGridState() {
    return this.dataGridState;
  }

  @Mutation
  [Mutations.SET_DATA_GRID_MY_VARIANT_LIST](model) {
    this.dataGridVariantResponseModel = model;
  }

  @Mutation
  [Mutations.SET_MY_DAFAULT_DATA_GRID_VARIANT](model) {
    this.dafaultDataGridVariantResponseModel = model;
  }

  @Mutation
  [Mutations.SET_DATA_GRID_STATE](model) {
    this.dataGridState = model;
  }

  @Action
  [Actions.GET_DATA_GRID_MY_VARIANT](dataGridName) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "General/GetDataGridMyVariantList?dataGridName=" + dataGridName
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_DATA_GRID_MY_VARIANT_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.CREATE_DATA_GRID_VARIANT](
    requestModel: DataGridVariantRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("General/CreateDataGridVariant", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_DATA_GRID_VARIANT](idDataGridVariant: number) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "General/DeleteDataGridVariant?idDataGridVariant=" + idDataGridVariant
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_DATA_GRID_VARIANT_DEFAULT](
    requestModel: DataGridVariantRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("General/UpdateDataGridVariantDefault", requestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_MY_DAFAULT_DATA_GRID_VARIANT](dataGridName) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "General/GetMyDefaultDataGridVariant?dataGridName=" + dataGridName
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_MY_DAFAULT_DATA_GRID_VARIANT,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SAVE_DATA_GRID_STATE](state) {
    this.context.commit(Mutations.SET_DATA_GRID_STATE, state);
  }
}
