export class CompanyMarketDataRequestModel {
  idCompanyMarketData: number;
  idPackage: number;
  idEmployeLevelStatus: number;
  employeeType: string;
  idEmployeePolicyType: number | null;
  marketDataType: number;
  currentYearMarketValue: number;
  newYearMarketValue: number;
  marketGrade: number;
}
