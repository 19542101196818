export class ImportExcelRequestModel {
  idCompany: number | null;
  excelName: string;
  isPastWageDataEntered: boolean;
  employeeList: Array<EmployeeRequestModel>;
  termYear: number | null;
  termMonth: number | null;
  idPolicyManagementOption: number | null;
}

export class EmployeeRequestModel {
  idEmployee: number;
  idCompany: number;
  externalIdEmployee: string;
  name: string;
  surname: string;
  positionCoefficient: number;
  budget: string;
  lastYearPerformance: string;
  thisYearPerformance: string;
  location: string;
  workUnit: string;
  department: string;
  position: string;
  firstManager: string;
  lastManager: string;
  normType: string;
  dateOfBirth: string | null;
  startDateOfEmployment: string;
  startDateOfPosition: string | null;
  positionCriticality: string | null;
  employeeCriticality: string | null;
  level: number;
  status: string;
  wage: number;
  title: string;
  gender: string;
  email: string;
  finalWage: number | null;
  employeeType: string;
  policyType: string;
  retirementStatus: string;
  numberOfSalariesInYear: number;
  employeeNote: string;
  employeeCompanyName: string;
  salesPremiumRate: number | null;
  subDepartment: string;
}
