import ApiService from "@/core/services/ApiService";
import { KeyValueResponseModel } from "@/models/global/KeyValueResponseModel";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { UserPasswordChangeRequestModel } from "../auth/_models/UserPasswordChangeRequestModel";
import { ExcelPackageResponseModel } from "../topbarManagement/_models/ResponseModels/ExcelPackageResponseModel";
import { RoleRequestModel } from "./_models/RequestModels/RoleRequestModel";
import { SaveCompanyAuthorizationRequestModel } from "./_models/RequestModels/SaveCompanyAuthorizationRequestModel";
import { SaveMenuAuthorizationRequestModel } from "./_models/RequestModels/SaveMenuAuthorizationRequestModel";
import { UserAuthorityRequestModel } from "./_models/RequestModels/UserAuthorityRequestModel";
import { UserRequestModel } from "./_models/RequestModels/UserRequestModel";
import { AuthorizationResponseModel } from "./_models/ResponseModels/AuthorizationResponseModel";
import { CompanyAuthorizationResponseModel } from "./_models/ResponseModels/CompanyAuthorizationResponseModel";
import { FilterTableForPopulationAuthorizationResponseModel } from "./_models/ResponseModels/FilterTableForPopulationAuthorizationResponseModel";
import { RoleActiveUsersResponseModel } from "./_models/ResponseModels/RoleActiveUsersResponseModel";
import { RoleResponseModel } from "./_models/ResponseModels/RoleResponseModel";
import { SelectedEmployeeResponseModel } from "./_models/ResponseModels/SelectedEmployeeResponseModel";
import { UserAuthorityResponseModel } from "./_models/ResponseModels/UserAuthorityResponseModel";
import { UserResponseModel } from "./_models/ResponseModels/UserResponseModel";

@Module
export default class UserManagementModule extends VuexModule {
  userRequestModel: UserRequestModel;
  usersResponseModel: Array<UserResponseModel>;
  roleResponseModel: Array<RoleResponseModel>;
  roleRequestModel: RoleRequestModel;
  roleActiveUsersResponseModel: Array<RoleActiveUsersResponseModel>;
  authorizationResponseModel: AuthorizationResponseModel;
  companyAuthorizationResponseModel: CompanyAuthorizationResponseModel;
  userCompanyComboList: Array<KeyValueResponseModel>;
  excelPackagesComboList: Array<ExcelPackageResponseModel>;
  userAuthorityList: Array<UserAuthorityResponseModel>;
  filterTableForPopulationAuthorizationResponseModel: FilterTableForPopulationAuthorizationResponseModel;
  selectedEmpList: Array<SelectedEmployeeResponseModel>;

  get getUserInformations() {
    return this.userRequestModel;
  }
  get getAllUsers() {
    return this.usersResponseModel;
  }
  get getAllRoles() {
    return this.roleResponseModel;
  }
  get getRoleInformations() {
    return this.roleRequestModel;
  }
  get getActiveUserListForRole() {
    return this.roleActiveUsersResponseModel;
  }
  get getMenuAuthorizationList() {
    return this.authorizationResponseModel;
  }
  get getCompanyAuthorizationList() {
    return this.companyAuthorizationResponseModel;
  }
  get getCurrentUserCompanyComboList() {
    return this.userCompanyComboList;
  }
  get getExcelPackageComboList() {
    return this.excelPackagesComboList;
  }
  get getUserAuthority() {
    return this.userAuthorityList;
  }
  get getPopulationAuthorizationDatasForUser() {
    return this.filterTableForPopulationAuthorizationResponseModel;
  }
  get getEmpList() {
    return this.selectedEmpList;
  }

  @Mutation
  [Mutations.SET_USER_LIST](userList) {
    this.usersResponseModel = userList;
  }

  @Mutation
  [Mutations.SET_USER_INFORMATIONS](user) {
    this.userRequestModel = user;
  }

  @Mutation
  [Mutations.SET_ROLE_LIST](roleList) {
    this.roleResponseModel = roleList;
  }

  @Mutation
  [Mutations.SET_ACTIVE_USER_LIST_FOR_ROLE](activeUserListForRole) {
    this.roleActiveUsersResponseModel = activeUserListForRole;
  }

  @Mutation
  [Mutations.SET_ROLE_INFORMATIONS](role) {
    this.roleRequestModel = role;
  }

  @Mutation
  [Mutations.SET_MENU_AUTHORIZATION_LIST_FOR_ROLE](menuAuthorizationList) {
    this.authorizationResponseModel = menuAuthorizationList;
  }

  @Mutation
  [Mutations.SET_COMPANY_AUTHORIZATION_LIST_FOR_USER](
    companyAuthorizationList
  ) {
    this.companyAuthorizationResponseModel = companyAuthorizationList;
  }

  @Mutation
  [Mutations.SET_USER_COMPANY_COMBO_LIST](userCompanyComboList) {
    this.userCompanyComboList = userCompanyComboList;
  }

  @Mutation
  [Mutations.SET_EXCEL_PACKAGES_COMBO_LIST](excelPackages) {
    this.excelPackagesComboList = excelPackages;
  }

  @Mutation
  [Mutations.SET_USER_AUTHORITY_LIST](userCompanyComboList) {
    this.userAuthorityList = userCompanyComboList;
  }

  @Mutation
  [Mutations.SET_SELECTED_EMPLOYEE_LIST](selectedEmpList) {
    this.selectedEmpList = selectedEmpList;
  }

  @Mutation
  [Mutations.SET_POPULATION_AUTHORIZATION_DATA_FOR_USER](model) {
    this.filterTableForPopulationAuthorizationResponseModel = model;
  }

  @Action
  [Actions.GET_USER_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetAllUsers")
        .then((response: any) => {
          // request succeeded
          if (response.result) {
            this.context.commit(Mutations.SET_USER_LIST, response.data);
          }
          resolve(response); // return response data to calling function
        })
        .catch((error) => {
          // request failed
          reject(error); // return error to calling function
        });
    });
  }

  @Action
  [Actions.ADD_USER](userRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/AddUser", userRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_USER](idUser) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/DeleteUser?id=" + idUser)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_USER](idUser) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetUser?id=" + idUser)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_USER_INFORMATIONS, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER](userRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/UpdateUser", userRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ROLE_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("User/ListRoles")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_ROLE_LIST, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ACTIVE_USER_LIST_FOR_ROLE](idRole) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/ActiveUsersListForRole?roleId=" + idRole)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_ACTIVE_USER_LIST_FOR_ROLE,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_ROLE](roleRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/InsertRole", roleRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_ROLE](idRole) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/DeleteRole?id=" + idRole)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_ROLE](idRole) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetRole?id=" + idRole)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(Mutations.SET_ROLE_INFORMATIONS, response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_ROLE](roleRequestModel: RoleRequestModel) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/UpdateRole", roleRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_MENU_AUTHORIZATION_LIST_FOR_ROLE](idRole) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetMenuAuthorizationList?roleId=" + idRole)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_MENU_AUTHORIZATION_LIST_FOR_ROLE,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SAVE_MENU_AUTHORIZATION_LIST](
    saveMenuAuthorizationRequestModel: SaveMenuAuthorizationRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "User/SaveMenuAuthorizationList",
        saveMenuAuthorizationRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_PASSWORD](
    userPasswordChangeRequestModel: UserPasswordChangeRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post("User/UpdateUserPassword", userPasswordChangeRequestModel)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_COMPANY_AUTHORIZATION_LIST_FOR_USER](idUser) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetCompanyAuthorizationList?idUser=" + idUser)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_COMPANY_AUTHORIZATION_LIST_FOR_USER,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SAVE_COMPANY_AUTHORIZATION_LIST](
    saveCompanyAuthorizationRequestModel: SaveCompanyAuthorizationRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "User/SaveCompanyAuthorizationList",
        saveCompanyAuthorizationRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_USER_COMPANY_COMBO_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetUserCompanyComboList")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_USER_COMPANY_COMBO_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_EXCEL_PACKAGES_COMBO_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetExcelPackagesComboList")
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_EXCEL_PACKAGES_COMBO_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_USER_AUTHORITY_LIST](idPackage) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/GetUserAuthorityList?idPackage=" + idPackage)
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_USER_AUTHORITY_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_SELECTED_EMPLOYEE_LIST](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "User/GetSelectedEmployeeList?idUser=" +
          payload.idUser +
          "&idPackage=" +
          payload.idPackage
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_SELECTED_EMPLOYEE_LIST,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_POPULATION_AUTHORIZATION_DATA_FOR_USER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        "User/GetPopulationAuthorizationDataForUser?idExcelPackage=" +
          payload.idExcelPackage +
          "&idUser=" +
          payload.idUser
      )
        .then((response: any) => {
          if (response.result) {
            this.context.commit(
              Mutations.SET_POPULATION_AUTHORIZATION_DATA_FOR_USER,
              response.data
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_OR_UPDATE_POPULATION_AUTHORIZATION](
    userAuthorityRequestModel: UserAuthorityRequestModel
  ) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "User/addOrUpdatePopulationAuthorization",
        userAuthorityRequestModel
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DEACTIVATE_USER](idUser) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/DeactivateUser?idUser=" + idUser)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ACTIVATE_USER](idUser) {
    return new Promise((resolve, reject) => {
      ApiService.get("User/ActivateUser?idUser=" + idUser)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
