import { EmployeeResponseModel } from "@/store/modules/companyManagement/_models/ResponseModels/FilterTableResponseModel";
import { Subject } from "rxjs";

const subject = new Subject();

export const sharedService = {
  sendIdExcelPackage: (idExcelPackage: number) =>
    subject.next({ idExcelPackage }),

  sendEmployeeList: (employeeList: Array<number>) =>
    subject.next({
      employeeList,
    }),

  sendEmployeeListByWageRange: (
    employeeListByWageRange: Array<EmployeeResponseModel>
  ) => subject.next({ employeeListByWageRange }),

  reloadWageRange: (reloadWageRange: boolean) =>
    subject.next({ reloadWageRange }),

  sendIdCompany: (idCompany: number) => subject.next({ idCompany }),

  sendFactorDataFlag: (factorDataFlag: boolean) =>
    subject.next({ factorDataFlag }),

  sendPolicyDataFlag: (policyDataFlag: boolean) =>
    subject.next({ policyDataFlag }),

  reloadChart: (reloadChartFlag: boolean, isDataUpdate: boolean) =>
    subject.next({ reloadChartFlag, isDataUpdate }),

  sendMenuStatusFlag: (menuStatusFlag: boolean) =>
    subject.next({ menuStatusFlag }),

  sendEmployeeListForFinalChart: (
    sendEmployeeListForFinalChart: Array<EmployeeResponseModel>
  ) => subject.next({ sendEmployeeListForFinalChart }),

  // sendChartNameToShow: (
  //   chartNameToShow: string,
  //   employeeListForChartChange: Array<number>
  // ) => subject.next({ chartNameToShow, employeeListForChartChange }),

  sendChartNameToShow: (
    chartNameToShow: string,
  ) => subject.next({ chartNameToShow}),

  reloadExcelPackageListFlag: (reloadExcelPackageListFlag: boolean) =>
    subject.next({ reloadExcelPackageListFlag }),

  reloadExcelPackageListFlagForAnotherPages: (
    reloadExcelPackageListFlagForAnotherPages: boolean
  ) => subject.next({ reloadExcelPackageListFlagForAnotherPages }),

  hideExcelPackageComboList: (hideExcelPackageComboListFlag: boolean) =>
    subject.next({ hideExcelPackageComboListFlag }),

  sendChartNameToShowSR: (chartNameToShowSR: string) =>
    subject.next({ chartNameToShowSR }),

  sendDeleteExcelPackageFlag: (deleteExcelPackageFlag: boolean) =>
  subject.next({ deleteExcelPackageFlag }),

  sendClickEvent: () => subject.next(),

  getClickEvent: () => subject.asObservable(),
};
