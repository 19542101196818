export class PreviewPageRequestModel {
  idCompany: number;
  idExcelPackage: number | null;
  // selectedChart: number;
  isDataUpdate: boolean;
  employeeList: Array<number> | null;
  checkedIdEmployeeList: Array<number>;
  checkedWorkUnits: Array<number>;
  checkedDepartments: Array<number>;
  checkedLevels: Array<number>;
  checkedPositions: Array<string>;
  checkedPercantageFilters: Array<number>;
  checkedFaoList: Array<number>;
  checkedPerformansList: Array<string>;
  checkedTitleList: Array<number>;
  checkedLocationList: Array<number>;
  checkedRetirementStatusList: Array<string>;
  checkedEmployeeTypeList: Array<string>;
  checkedPolicyTypeList: Array<number>;
  checkedEmployeeCompanyList: Array<number>;
}
