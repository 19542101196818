import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/auth/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CompanyManagementModule from "./modules/companyManagement/CompanyManagementModule";
import UserManagementModule from "./modules/userManagement/UserManagementModule";
import TopbarManagementModule from "./modules/topbarManagement/TopbarManagementModule";
import CompanyDashboardModule from "./modules/companyManagement/CompanyDashboardModule";
import OrganizationChartModule from "./modules/companyManagement/OrganizationChartModule";
import GeneralManagementModule from "./modules/generalManagement/GeneralManagementModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    TopbarManagementModule,
    UserManagementModule,
    CompanyManagementModule,
    CompanyDashboardModule,
    OrganizationChartModule,
    GeneralManagementModule
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));
export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
