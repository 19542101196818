export class CompanyRequestModel {
  id: number | null;
  idSector: number | null;
  companyCode: string;
  companyName: string;
  email: string;
  address: string;
  phoneNumber: string;
  isParentCompany: boolean | null;
}
