/* eslint-disable no-var */
import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "@/router";

//Service to call HTTP request via Axios
class ApiService {
  //Property to share vue instance
  public static vueInstance: App;

  //Initialize vue axios
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  //Set the default HTTP request headers
  public static setHeader(): void {
    const user = JwtService.getUserView();
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.accessToken}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "application/json";
  }

  public static get(resource: string): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios
      .get(`${resource}`)
      .then((response: any) => {

        //Farklı sekmelerde farklı kullanıcı bilgileri ile giriş yapıldığında son giriş yapan kullanıcı bilgileri local'e setleniyor.
        //Diğer kullanıcıyı dışarı atıyoruz. 
        if (response.data.result == false) {
          const user = JwtService.getUserView();
          const sessionUserId: any = JwtService.getUserId();

          if (user.idUser != sessionUserId) {
            sessionStorage.removeItem("id_excel_package");
            sessionStorage.removeItem("id_company");
            sessionStorage.removeItem("id_policy_management_option");
            sessionStorage.removeItem("id_user");
            router.push({ name: "sign-in" });
          }
        }

        return response.data;
      })
      .catch((error) => {
        console.log(error);
        if (error.message == "Request failed with status code 401") {
          Swal.fire({
            icon: "error",
            text: "Token süreniz bittiği için yeniden giriş yapmalısınız!",
            showConfirmButton: true,
            width: 500,
          }).then(async (resp) => {
            await router.push({ name: "sign-in" });
          });
        } else {
          Swal.fire({
            icon: "warning",
            text: error.message,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            width: 500,
            confirmButtonText: "Tamam",
          });
        }
        localStorage.removeItem("user_view");
      });
  }

  public static post(
    resource: string,
    params: AxiosRequestConfig | any
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios
      .post(`${resource}`, params)
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          Swal.fire({
            icon: "error",
            text: "Token süreniz bittiği için yeniden giriş yapmalısınız!",
            showConfirmButton: true,
            width: 500,
          }).then(async (resp) => {
            await router.push({ name: "sign-in" });
          });
        } else {
          Swal.fire({
            icon: "warning",
            text: error.message,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            width: 500,
            confirmButtonText: "Tamam",
          });
        }
        localStorage.removeItem("user_view");
      });
  }

  // public static query(
  //   resource: string,
  //   params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.get(resource, params);
  // }

  // public static get(
  //   resource: string,
  //   slug = "" as string
  // ): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  // }

  // public static update(
  //   resource: string,
  //   slug: string,
  //   params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  // }

  // public static put(
  //   resource: string,
  //   params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.put(`${resource}`, params);
  // }

  // public static delete(resource: string): Promise<AxiosResponse> {
  //   return ApiService.vueInstance.axios.delete(resource);
  // }
}

export default ApiService;
