import { EmployeeWageInfoByTermResponseModel } from "../ResponseModels/EmployeeWageInfoByTermResponseModel";
import { EmployeeWageInfoForTableResponseModel } from "../ResponseModels/EmployeeWageInfoForTableResponseModel";

export class EmployeeWageInfoRequestModel {
  idEmployee: number | null;
  employeeNameSurname: string;
  firstManager: string;
  lastManager: string;
  seniorityYear: string;
  locationName: string;
  levelName: string;
  workUnitName: string;
  positionName: string;
  idExcelPackage: number | null;
  wage: number | null;
  systemRecommendationWage: number | null;
  finalWage: number | null;
  finalIncreaseRate: number | null;
  individualPolicyPIR: number | null;
  advancedPolicy: number | null;
  crAfterFirstIncrement: number | null;
  currentPIR: number | null;
  sysRcmPosition: number | null;
  finalPosition: number | null;
  managerRecommendationWage: number | null;
  managerIncreaseRate: number | null;
  positionCriticality: boolean | null;
  employeeCriticality: string;
  employeeWageInfoByTerm: EmployeeWageInfoByTermResponseModel;
  managerRecommendationNote: string;
  beforeFAO: number | null;
  afterFAO: number | null;
  employeeNote: string | null;
  employeeWageInfoForTable: EmployeeWageInfoForTableResponseModel;
}
