import { CurrentStructureChartResponseModel } from "./CurrentStructureChartResponseModel";
import { FilterTableResponseModel } from "./FilterTableResponseModel";
import { FinalChartResponseModel } from "./FinalChartResponseModel";
import { ManagerRecommendationChartResponseModel } from "./ManagerRecommendationChartResponseModel";
import { SummaryTableResponseModel } from "./SummaryTableResponseModel";
import { SystemRecommendationChartResponseModel } from "./SystemRecommendationChartResponseModel";
import { WageRangeResponseModel } from "./WageRangeResponseModel";

export class PreviewPageResponseModel {
  currentStructureChartResponseModel: CurrentStructureChartResponseModel;
  systemRecommendationChartResponseModel: SystemRecommendationChartResponseModel;
  finalChartResponseModel: FinalChartResponseModel;
  managerRecommendationChartResponseModel: ManagerRecommendationChartResponseModel;
  filterTableResponseModel: FilterTableResponseModel;
  summaryTableResponseModel: Array<SummaryTableResponseModel>;
  wageRangeResponseModel: WageRangeResponseModel;
}
