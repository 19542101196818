
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import tr from "devextreme/localization/messages/tr.json";
import { locale, loadMessages } from "devextreme/localization";
import { Tooltip } from "bootstrap";

new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
  delay: { show: 500, hide: 500 },
});

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    loadMessages(tr);
    locale("tr");

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
