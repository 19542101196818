enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  //Auth Module
  LOGIN = "login",
  FORGOT_PASSWORD = "forgotPassword",
  LOGOUT = "logout",
  GET_ROLE_MODULE_LIST = "getRoleModuleList",
  GET_ROLE_MENU_LIST = "getRoleMenuList",
  GET_ROLE_MENU_ACTION_LIST = "getRoleMenuActionList",
  VERIFY_AUTH = "verifyAuth",
  REGISTER = "register",
  VERIFY_USER_PASSWORD = "verifyUserPassword",

  //User Management Module
  GET_USER_LIST = "getUserList",
  ADD_USER = "addUser",
  DELETE_USER = "deleteUser",
  GET_USER = "getUser",
  UPDATE_USER = "updateUser",
  GET_ROLE_LIST = "getRoleList",
  GET_ACTIVE_USER_LIST_FOR_ROLE = "getActiveUsersListForRole",
  ADD_ROLE = "addRole",
  DELETE_ROLE = "deleteRole",
  GET_ROLE = "getRole",
  UPDATE_ROLE = "updateRole",
  GET_MENU_AUTHORIZATION_LIST_FOR_ROLE = "getMenuAuthorizationListForRole",
  SAVE_MENU_AUTHORIZATION_LIST = "saveMenuAuthorizationList",
  UPDATE_USER_PASSWORD = "updateUserPassword",
  GET_COMPANY_AUTHORIZATION_LIST_FOR_USER = "getCompanyAuthorizationListForUser",
  SAVE_COMPANY_AUTHORIZATION_LIST = "saveCompanyAuthorizationList",
  GET_USER_COMPANY_COMBO_LIST = "getUserCompanyComboList",
  GET_EXCEL_PACKAGES_COMBO_LIST = "getExcelPackagesComboList",
  GET_USER_AUTHORITY_LIST = "getUserAuthorityList",
  GET_SELECTED_EMPLOYEE_LIST = "getSelectedEmployeeList",
  GET_POPULATION_AUTHORIZATION_DATA_FOR_USER = "getPopulationAuthorizationDataForUser",
  ADD_OR_UPDATE_POPULATION_AUTHORIZATION = "addOrUpdatePopulationAuthorization",
  GET_USER_AUTHORITY_BY_EXCEL_PACKAGE = "getUserAuthorityByExcelPackage",
  DEACTIVATE_USER = "deactivateUser",
  ACTIVATE_USER = "activateUser",

  //Company Management Module
  INSERT_EXCEL_EMPLOYEE_DATA = "insertExcelEmployeeData",
  GET_EXCEL_EMPLOYEE_DATA = "getExcelEmployeeData",
  GET_POLICY_INFO_FOR_EXCEL_PACKAGE_ID = "getPolicyInfoForExcelPackageId",
  GET_POLICY_GRAND_TOTAL_INFO_FOR_EXCEL_PACKAGE_ID = "getPolicyGrandTotalInfoForExcelPackageId",
  INSERT_WAGE_POLICY = "insertWagePolicy",
  GET_WORK_UNIT_WAGE_FACTOR_LIST = "getWorkUnitWageFactorList",
  INSERT_WAGE_FACTOR_FOR_WORK_UNIT = "insertWageFactorForWorkUnit",
  GET_LEVEL_SENIORITY_FACTOR_LIST = "getLevelSeniorityFactorList",
  INSERT_SENIORITY_FACTOR_FOR_LEVEL = "insertSeniorityFactorForLevel",
  GET_POSITION_CRITICALITY_FACTOR_LIST = "getPositionCriticalityFactorList",
  INSERT_POSITION_CRITICALITY_FACTOR = "insertPositionCriticalityFactor",
  GET_COMPANY_LIST = "getCompanyList",
  GET_COMPANY = "getCompany",
  UPDATE_COMPANY = "updateCompany",
  GET_SECTOR_LIST = "getSectorList",
  UPDATE_INCREASE_RATE_BY_EMPLOYEE = "updateIncreaseRateByEmployee",
  DELETE_EXCEL_PACKAGE = "deleteExcelPackage",
  ADD_OR_UPDATE_EMPLOYEE_TO_EXCEL_PACKAGE = "addOrUpdateEmployeeToExcelPackage",
  GET_EMPLOYEE_LEVELS = "getEmployeeLevels",
  GET_EMPLOYEE_STATUS = "getEmployeeStatus",
  GET_EMPLOYEE_WORK_UNITS = "getEmployeeWorkUnits",
  GET_EMPLOYEE_DEPARTMENTS = "getEmployeeDepartments",
  GET_EMPLOYEE_POSITIONS = "getEmployeePositions",
  GET_EMPLOYEE_NORM_TYPES = "getEmployeeNormTypes",
  GET_HISTORY_OF_EXCEL_IMPORT = "getHistoryOfExcelImport",
  RETRY_TO_COMPLETE_EXCEL_IMPORT_FOR_EMPLOYEE = "RetryToCompleteExcelImportForEmployee",
  GET_RATING_SCENARIO_LIST = "getRatingScenarioList",
  INSERT_RATING_SCENARIO = "insertRatingScenario",
  GET_MAXMIN_FILTER = "getMaxMinFilter",
  UPDATE_MAXMIN_FILTER = "updateMaxMinFilter",
  GET_EMPLOYEE_POSITION_CRITICALITY_LEVELS = "getEmployeePositionCriticalityLevels",
  GET_LEVEL_LIST_BY_SECTOR = "getLevelListBySector",
  INSERT_COMPANY_MARKET_DATA = "insertCompanyMarketData",
  GET_EMPLOYEE_BY_ID = "getEmployeeById",
  GET_EMPLOYEE_POSITION_LIST = "getEmployeePositionForPositionManagement",
  ADD_EMPLOYEE_POSITION = "addEmployeePosition",
  UPDATE_EMPLOYEE_POSITION = "updateEmployeePosition",
  GET_EMPLOYEE_POSITION_BY_ID = "getEmployeePositionById",
  GET_ALL_SCENARIO_FOR_COMPANY = "getAllScenarioForCompany",
  COPY_SCENARIO_FROM_REFERENCE_SCENARIO = "copyScenarioFromReferenceScenario",
  GET_EMPLOYEE_PERFORMANCES = "getEmployeePerformances",
  UPDATE_MANAGER_INCREASE_RATE_BY_EMPLOYEE = "updateManagerIncreaseRateByEmployee",
  GET_EMPLOYEE_TITLES = "getEmployeeTitles",
  DELETE_EMPLOYEE = "deleteEmployee",
  COLLECTIVE_HIDE_SELECTED_EMPLOYEES = "collectiveHideSelectedEmployees",
  UPDATE_EMPLOYEE_VISIBLE_INFORMATION = "updateEmployeeVisibleInformation",
  GET_EMPLOYEE_LOCATIONS = "getEmployeeLocations",
  GET_LOCATION_FACTORS_LIST = "getLocationFactorsList",
  INSERT_LOCATION_FACTOR = "insertLocationFactor",
  GET_SCENARIO_STATUS_BY_ID = "getScenarioStatusById",
  GET_TERM_YEAR_COMBO_LIST = "getTermYearComboList",
  SEND_SALARY_LETTER_MAIL = "SendSalaryLetterMail",
  CALCULATE_SENIORITY_INFORMATION = "calculateSeniorityInformation",
  COLLECTIVE_FINAL_WAGE_RATE_INCREASE_FOR_SELECTED_EMPLOYEES = "collectiveFinalWageRateIncreaseForSelectedEmployees",
  COLLECTIVE_FINAL_WAGE_INCREASE_FOR_SELECTED_EMPLOYEES = "collectiveFinalWageIncreaseForSelectedEmployees",
  COLLECTIVE_MANAGER_RECOMMENDATION_WAGE_RATE_INCREASE_FOR_SELECTED_EMPLOYEES = "collectiveManagerRecommendationWageRateIncreaseForSelectedEmployees",
  COLLECTIVE_MANAGER_RECOMMENDATION_WAGE_INCREASE_FOR_SELECTED_EMPLOYEES = "collectiveManagerRecommendationWageIncreaseForSelectedEmployees",
  GET_POLICY_FACTOR_NOTE = "getPolicyFactorNote",
  ADD_OR_UPDATE_POLICY_FACTOR_NOTE = "addOrUpdatePolicyFactorNote",
  IS_THERE_ANY_RECORDED_POLICY_FACTOR_NOTE = "isThereAnyRecordedPolicyFactorNote",
  UPDATE_EMPLOYEE_NOTE = "updateEmployeeNote",
  GET_ALL_POLICY_FACTOR_NOTES = "getAllPolicyFactorNotes",
  INSERT_ALL_RATING_SCENARIO_TO_VALUE_FOR_PACKAGE = "insertAllRatingScenarioToValueForPackage",
  GET_POLICY_MANAGEMENT_OPTION_COMBO_LIST = "getPolicyManagementOptionComboList",
  GET_EMPLOYEE_POLICY_TYPES = "getEmployeePolicyTypes",
  GET_PIR_CALCULATION_PARAMETER = "getPırCalculationParameter",
  UPDATE_PIR_CALCULATION_PARAMETER = "updatePırCalculationParameter",
  COPY_POLICY_FROM_REFERENCE_EXCEL_PACKAGE = "copyPolicyFromReferenceExcelPackage",
  INSERT_POLICY_FROM_EXCEL = "insertPolicyFromExcel",
  GET_EMPLOYEE_COMPANY_FOR_NEW_EMPLOYEE = "getEmployeeCompanyForNewEmployee",
  GET_POSITION_EMPLOYEES = "getPositionEmployees",
  UPDATE_SALES_PREMIUM_RATE_BY_EMPLOYEE = "updateSalesPremiumRateByEmployee",
  UPDATE_FINAL_wAGE_BY_EMPLOYEE = "updateFinalWageByEmployee",
  UPDATE_SCENARIO_NAME = "updateScenarioName",

  //Topbar Management Module
  GET_EMPLOYEE_EXCEL_PACKAGES_COMBO_LIST = "getEmployeeExcelPackagesComboList",
  SAVE_ID_EXCEL_PACKAGE = "saveIdExcelPackage",
  GET_COMPANY_COMBO_LIST_BY_USER = "getCompanyComboListByUser",
  SAVE_ID_COMPANY = "saveIdCompany",
  SAVE_ID_POLICY_MANAGEMENT_OPTION = "saveIdPolicyManagementOption",
  DESTROY_ID_POLICY_MANAGEMENT_OPTION = "destroyIdPolicyManagementOption",
  DESTROY_ID_EXCEL_PACKAGE = "destroyIdExcelPackage",
  ISTHERE_ANY_RECORDED_FACTOR_OR_POLICY_DATA = "isThereAnyRecordedFactorOrPolicyData",
  UPDATE_USER_ACTIVITY = "updateUserActivity",
  UPDATE_USER_ACTIVITY_FOR_COMPANY = "updateUserActivityForCompany",
  SAVE_ID_EMPLOYEE_MANAGEMENT_OPTION = "saveIdEmployeeManagementOption",
  DESTROY_ID_EMPLOYEE_MANAGEMENT_OPTION = "destroyIdEmployeeManagementOption",
  SAVE_ID_WAGE_MANAGEMENT_OPTION = "saveIdWageManagementOption",
  DESTROY_ID_WAGE_MANAGEMENT_OPTION = "destroyIdWageManagementOption",

  //Company Dashboard Module
  UPDATE_CALCULATE_MATHEMATICAL_VALUES_FOR_DASHBOARD = "updateCalculateMathematicalValuesForDashboard",
  GET_WAGE_INFO_FOR_EMPLOYEE = "getWageInfoForEmployee",
  UPDATE_WAGE_INFO_FOR_EMPLOYEE = "updateWageInfoForEmployee",
  UPDATE_MANAGER_WAGE_INFO_FOR_EMPLOYEE = "updateManagerWageInfoForEmployee",
  GET_EMPLOYEE_DATA_FOR_EXCEL_EXPORT = "getEmployeeDataForExcelExport",
  GET_EXCEL_TEMPLATES = "getExcelTemplates",
  GET_DASHBOARD_DATA = "getDashboardData",
  COMPLETE_SCENARIO = "completeScenario",
  GET_PREVIEW_PAGE_DATA = "getPreviewPageData",
  SAVE_PREVIEW_PAGE_REQUEST_MODEL = "savePreviewPageRequestModel",
  GET_EMPLOYEE_WAGE_CHANGE_LIST = "getEmployeeWageChangeList",
  UPDATE_PREVIEW_PAGE_CHART_VIEW_FOR_USER = "updatePreviewPageChartViewForUser",
  GET_PREVIEW_PAGE_CHART_VIEW_FOR_USER = "getPreviewPageChartViewForUser",
  GO_BACK_TO_SYSTEM_RECOMMENDATION = "goBackToSystemRecommendation",

  //General Management Module
  CREATE_DATA_GRID_VARIANT = "createDataGridVariant",
  DELETE_DATA_GRID_VARIANT = "deleteDataGridVariant",
  GET_DATA_GRID_MY_VARIANT = "getDataGridMyVariant",
  UPDATE_DATA_GRID_VARIANT_DEFAULT = "updateDataGridVariantDefault",
  GET_MY_DAFAULT_DATA_GRID_VARIANT = "getMyDefaultDataGridVariant",
  SAVE_DATA_GRID_STATE = "saveDataGridState",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  //Auth Module
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  PURGE_AUTH = "purgeAuth",
  SET_PASSWORD = "setPassword",
  SET_ROLE_MODULE_LIST = "setRoleModuleList",
  SET_ROLE_MENU_LIST = "setRoleMenuList",
  SET_ROLE_MENU_ACTION_LIST = "setRoleMenuActionList",
  SET_VERIFY_USER_PASSWORD = "setVerifyUserPassword",

  //User Management Module
  SET_USER_LIST = "setUserList",
  SET_USER_INFORMATIONS = "setUserInformations",
  SET_ROLE_LIST = "setRoleList",
  SET_ACTIVE_USER_LIST_FOR_ROLE = "setActiveUsersListForRole",
  SET_ROLE_INFORMATIONS = "setRoleInformations",
  SET_MENU_AUTHORIZATION_LIST_FOR_ROLE = "setMenuAuthorizationListForRole",
  SET_COMPANY_AUTHORIZATION_LIST_FOR_USER = "setCompanyAuthorizationListForUser",
  SET_USER_COMPANY_COMBO_LIST = "setUserCompanyComboList",
  SET_EXCEL_PACKAGES_COMBO_LIST = "setExcelPackagesComboList",
  SET_USER_AUTHORITY_LIST = "setUserAuthorityList",
  SET_SELECTED_EMPLOYEE_LIST = "setSelectedEmployeeList",
  SET_POPULATION_AUTHORIZATION_DATA_FOR_USER = "setPopulationAuthorizationDataForUser",

  //Company Management Module
  SET_EXCEL_EMPLOYEE_DATA = "setExcelEmployeeData",
  SET_POLICY_INFO_FOR_EXCEL_PACKAGE_ID = "setPolicyInfoForExcelPackageId",
  SET_POLICY_GRAND_TOTAL_INFO_FOR_EXCEL_PACKAGE_ID = "setPolicyGrandTotalInfoForExcelPackageId",
  SET_WORK_UNIT_WAGE_FACTOR_LIST = "setWorkUnitWageFactorList",
  SET_LEVEL_SENIORITY_FACTOR_LIST = "setLevelSeniorityFactorList",
  SET_POSITION_CRITICALITY_FACTOR_LIST = "setPositionCriticalityFactorList",
  SET_COMPANY_DASHBOARD_STATISTIC = "setCompanyDashboardStatistic",
  SET_COMPANY_LIST = "setCompanyList",
  SET_COMPANY = "setCompany",
  SET_SECTOR_LIST = "setSectorList",
  SET_EMPLOYEE_LEVELS_LIST = "setEmployeeLevelsList",
  SET_EMPLOYEE_STATUS_LIST = "setEmployeeStatusList",
  SET_EMPLOYEE_WORK_UNITS_LIST = "setEmployeeWorkUnitsList",
  SET_EMPLOYEE_DEPARTMENTS_LIST = "setEmployeeDepartmentsList",
  SET_EMPLOYEE_POSITIONS_LIST = "setEmployeePositionsList",
  SET_EMPLOYEE_NORM_TYPES_LIST = "setEmployeeNormTypesList",
  SET_HISTORY_OF_EXCEL_IMPORT_LIST = "setHistoryOfExcelImportList",
  SET_RATING_SCENARIO_LIST = "setRatingScenarioList",
  SET_MAXMIN_FILTER = "setMaxMinFilter",
  SET_EMPLOYEE_POSITION_CRITICALITY_LEVELS = "setEmployeeCriticalityLevels",
  SET_LEVEL_LIST_BY_SECTOR = "setLevelListBySector",
  SET_EMPLOYEE = "setEmployee",
  SET_EMPLOYEE_POSITION_LIST = "setEmployeePositionListForPositionManagement",
  SET_EMPLOYEE_POSITION = "setEmployeePosition",
  SET_ALL_SCENARIO_FOR_COMPANY = "setAllScenarioForComapny",
  SET_EMPLOYEE_PERFORMANCES = "setEmployeePerformances",
  SET_EMPLOYEE_TITLES = "setEmployeeTitles",
  SET_EMPLOYEE_LOCATIONS = "setEmployeeLocations",
  SET_LOCATION_FACTORS_LIST = "setLocationFactorsList",
  SET_SCENARIO_STATUS = "setScenarioStatus",
  SET_TERM_YEAR_COMBO_LIST = "setTermYearComboList",
  SET_USER_AUTHORITY_BY_EXCEL_PACKAGE = "setUserAuthorityByExcelPackage",
  SET_POLICY_FACTOR_NOTE = "setPolicyFactorNote",
  SET_POLICY_FACTOR_NOTE_KEY_VALUE_MODEL = "setPolicyFactorNoteKeyValueModel",
  SET_ALL_POLICY_FACTOR_NOTES = "setAllPolicyFactorNotes",
  SET_POLICY_MANAGEMENT_OPTION_COMBO_LIST = "setPolicyManagementOptionComboList",
  SET_EMPLOYEE_POLICY_TYPES_LIST = "setEmployeePolicyTypesList",
  SET_GET_PIR_CALCULATION_PARAMETER = "setGetPirCalculationParameter",
  SET_EMPLOYEE_COMPANY_FOR_NEW_EMPLOYEE = "setEmployeeCompanyForNewEmployee",
  SET_POSITION_EMPLOYEE_LIST = "setPositionEmployeeList",

  //Topbar Management Module
  SET_EMPLOYEE_EXCEL_PACKAGES_COMBO_LIST = "setEmployeeExcelPackagesComboList",
  SET_ID_EXCEL_PACKAGE = "setIdExcelPackage",
  SET_COMPANY_COMBO_LIST_BY_USER = "setCompanyComboListByUser",
  SET_ID_COMPANY = "setIdCompany",
  SET_ID_POLICY_MANAGEMENT_OPTION = "setIdPolicyManagementOption",
  SET_FACTOR_OR_POLICY_DATA = "setIsThereAnyRecordedFactorOrPolicyData",
  SET_ID_EMPLOYEE_MANAGEMENT_OPTION = "setIdEmployeeManagementOption",
  SET_ID_WAGE_MANAGEMENT_OPTION = "setIdWageManagementOption",

  //Company Dashboard Module
  SET_WAGE_INFO_FOR_EMPLOYEE = "setWageInfoForEmployee",
  SET_EXCEL_TEMPLATES = "setExcelTemplates",
  SET_DASHBOARD_DATA = "setDashboardData",
  SET_EMPLOYEE_DATA_FOR_EXCEL_EXPORT = "setEmployeeDataForExcelExport",
  SET_PREVIEW_PAGE_DATA = "setPreviewPageData",
  SET_PREVIEW_PAGE_REQUEST_MODEL = "setPreviewPageRequestModel",
  SET_UPDATE_CALCULATE_MATHEMATICAL_VALUES_FOR_DASHBOARD = "setUpdateCalculateMathematicalValuesForDashboard",
  SET_EMPLOYEE_WAGE_CHANGE_LIST = "setEmployeeWageChangeList",
  SET_PREVIEW_PAGE_CHART_VIEW_FOR_USER = "setPreviewPageChartViewForUser",

  //General Management Module
  SET_DATA_GRID_MY_VARIANT_LIST = "setDataGridMyVariantList",
  SET_MY_DAFAULT_DATA_GRID_VARIANT = "setMyDefaultDataGridVariant",
  SET_DATA_GRID_STATE = "setDataGridState",
}

export { Actions, Mutations };
